import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

declare let gtag: Function;
@Injectable()
export class GAEventService {

    constructor(private router: Router) {
    }

    public init() {
        this.listenForRouteChanges();
        try {
            const script1 = document.createElement('script');
            script1.async = true;
            script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticsKey;
            document.head.appendChild(script1);

            const script2 = document.createElement('script');
            script2.innerHTML = `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '` + environment.googleAnalyticsKey + `', {
                    'custom_map': {'dimension1': "ClientId"}
                  }, {'send_page_view': false});
                `;
            document.head.appendChild(script2);
            const ckID = this.getCookieValue("_gid");
        } catch (ex) {
            console.error(ex);
        }
    }

    private listenForRouteChanges() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                gtag('config', environment.googleAnalyticsKey, {
                    'page_path': event.urlAfterRedirects,
                });
            }
        });
    }

      
    public getCookieValue(name: any){
        return  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
    }


    public emitEvent(eventCategory: any,
        eventAction: any,
        eventLabel: any,
        eventValue: number) {
        gtag('event', eventAction, {
            'event_category': eventCategory,
            'event_label': eventLabel,
            'value': eventValue

        });
    }
}