<div class="case-container col-sm-12 p-0 mt-2">
  <div class="row text-3xl pb-2 m-0 title-color">
    <span id="case-title"
      >{{ isDUNSCheck ? "DUNS Status" : "Case Status" }}
    </span>
  </div>

  <!-- Case Form -->
  <ng-container>
    <ng-container *ngIf="!showCaseDetailSection">
      <form [formGroup]="caseForm" (ngSubmit)="onCaseFormSubmit()">
        <fieldset>
          <div class="row m-0 dyk-border col-sm-12 card-padding">
            <div class="col-sm-12 text-xl semi-bold pt-3 title-color">
              Best way to get help
            </div>
            <div class="col-xs-12 col-sm-12 text-md mt-3 text-color pl-0">
              {{
                isDUNSCheck
                  ? "Please click submit to get a status on your DUNS request"
                  : "Please enter the case number to know the status"
              }}
            </div>
            <alert
              class="mt-2 alert-width"
              *ngIf="serverErrormessage"
              type="error"
              [dismissible]="true"
              (onClosed)="clearMessage()"
            >
              <img
                class="mr-2 mb-1"
                height="20"
                width="20"
                src="../../../../assets/images/feature/error.png"
                alt="form error"
              />
              <span>{{ serverErrormessage }}</span>
            </alert>
            <div class="col-sm-6 mt-3 pl-0" *ngIf="!isDUNSCheck">
              <div class="text-md text-color">Case Number*</div>
              <div class="text-md mt-2">
                <input
                  class="form-control input-box"
                  type="text"
                  formControlName="caseNumber"
                  [ngClass]="{
                    invalid: f.caseNumber.errors && f.caseNumber.errors.pattern
                  }"
                />
              </div>
              <div *ngIf="f.caseNumber.errors" class="danger-text">
                <div *ngIf="f.caseNumber.errors.pattern">
                  <img
                    class="mb-1"
                    height="20"
                    width="20"
                    src="../../../../assets/images/feature/error.png"
                    alt="Case Number pattern error"
                  />
                  Case Number should start with 2 to 9 and can accept only 8
                  digit numbers
                </div>
              </div>
            </div>
            <div
              class="col-xs-12 mt-3 pl-0"
              [ngClass]="{ 'col-sm-6': !isDUNSCheck, 'col-sm-12': isDUNSCheck }"
            >
              <div class="text-md text-color">Email Id*</div>
              <div class="text-md mt-2">
                <input
                  class="form-control input-box"
                  type="text"
                  formControlName="emailID"
                  readonly
                />
              </div>
            </div>
            <div
              class="col-xs-12 col-sm-12 mt-4 mb-3 text-m pl-0 d-flex justify-content-xs-start justify-content-md-end"
            >
              <button
                type="submit"
                class="btn btn-primary float-right btn-height"
                [disabled]="!caseForm.valid && !isDUNSCheck"
              >
                {{ selectedFinalData?.data?.primary || "Submit" }}
              </button>
            </div>
          </div>
        </fieldset>
      </form>
    </ng-container>
    <!-- Case Detail Section -->
    <div *ngIf="showCaseDetailSection" class="m-0 dyk-border card-padding">
      <div class="text-xl semi-bold p-0 pt-3 case-title-color">
        {{ isDUNSCheck ? "DUNS Details" : "Case Details" }}
      </div>
      <div
        *ngIf="!caseStatus && !isDUNSCheck"
        class="col-sm-12 text-xl semi-bold p-0 pt-3"
      >
        No Records Found For Case {{ caseForm.value?.caseNumber }}
      </div>
      <div
        *ngIf="!records?.length && isDUNSCheck"
        class="col-sm-12 text-xl semi-bold p-0 pt-3"
      >
        No Records Found For Email ID {{ caseForm.value?.emailID }}
      </div>
      <div *ngIf="serverSuccessmessage" class="text-xl semi-bold p-1">
        {{ serverSuccessmessage }}
      </div>

      <ng-container *ngIf="records?.length && isDUNSCheck">
        <ng-container *ngFor="let data of records">
          <table class="table table-bordered">
            <thead>
              <tr class="case-table-header">
                <!-- Comment the thead if table head is not needed for DUNS Status -->
                <td colspan="2">Case {{ data?.CaseNumber }}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Product Description</td>
                <td>
                  {{ data?.Product_Description }}
                </td>
              </tr>
              <tr>
                <td>Case Resolution</td>
                <td>
                  {{ data?.Case_Resolution }}
                </td>
              </tr>
              <tr>
                <td>Customer DUNS</td>
                <td>{{ data?.Customer_DUNS }}</td>
              </tr>
              <tr>
                <td>Expected Due Date</td>
                <td>
                  {{
                    data?.Expected_Due_Date
                      | date : "dd-MM-yyyy HH:mm a" : "EST"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </ng-container>

      <table *ngIf="caseStatus && !isDUNSCheck" class="table table-bordered">
        <thead>
          <tr class="case-table-header">
            <td colspan="2">Case {{ caseForm.value?.caseNumber }}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Contact Name</td>
            <td>
              {{
                caseStatus?.Contact?.FirstName +
                  " " +
                  caseStatus?.Contact?.LastName
              }}
            </td>
          </tr>
          <tr>
            <td>Case Created Date</td>
            <td>
              {{
                caseStatus?.CreatedDate | date : "dd-MM-yyyy HH:mm a" : "EST"
              }}
            </td>
          </tr>
          <tr>
            <td>Expected Response Date</td>
            <td>
              {{
                caseStatus?.Response_Due_Date
                  | date : "dd-MM-yyyy HH:mm a" : "EST"
              }}
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{{ caseStatus?.Status }}</td>
          </tr>
          <tr *ngIf="caseStatus?.StatusMessage">
            <td>Message</td>
            <td>
              {{ caseStatus?.StatusMessage }}
            </td>
          </tr>
          <tr *ngIf="caseStatus?.LastEmail">
            <td>Last Email</td>
            <td>{{ caseStatus?.LastEmail }}</td>
          </tr>
        </tbody>
      </table>
      <ng-container
        *ngIf="caseStatus?.Status === 'Need More Info From Customer' && needMoreInfoForm"
      >
        <div
          class="col-xs-12 col-sm-12 mt-4 mb-3 text-m pl-0 d-flex justify-content-xs-start justify-content-md-end"
        >
          <button type="submit" class="btn btn-primary float-right btn-height">
            <a
              (click)="redirectTo(caseStatus?.SupportFormURL)"
              class="text-nowrap footer-terms"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Update Case, opens in a new window"
              >Update Case</a
            >
          </button>
        </div>
      </ng-container>
      <ng-container
        *ngIf="caseStatus?.Status === 'Customer to Confirm Fix' && displayForm"
      >
        <form
          [formGroup]="updateCaseForm" 
          novalidate
        >
          <div class="row">
            <div class="text-md text-color col-sm-6 m-t-10">
              Please Confirm whether your issue is resolved
            </div>
            <div class="col-sm-6 pt-3">
              <label class="radio-inline m-r-10">
                <input
                  type="radio"
                  value="Yes"
                  formControlName="isResolved"
                  required
                />
                Yes
              </label>
              <label class="radio-inline">
                <input
                  type="radio"
                  value="No"
                  formControlName="isResolved"
                  required
                />
                No
              </label>
            </div>
          </div>
          <div
            class="col-xs-12 col-sm-12 mt-4 mb-3 text-m pl-0 d-flex justify-content-xs-start justify-content-md-end"
          >
            <button
              type="submit" *ngIf="g.isResolved.value!='No'" (click)="onUpdateCaseFormSubmit()" 
              class="btn btn-primary float-right btn-height"
              [disabled]="!updateCaseForm.valid"
              rel="noopener noreferrer"
              aria-label="Submit button for yes, opens in a new window"
            >
              Submit
            </button>

            <button  *ngIf="g.isResolved.value==='No'" (click)="redirectTo(caseStatus?.SupportFormURL)"
              type="submit"
              class="btn btn-primary float-right btn-height"
             
             
              rel="noopener noreferrer"
              aria-label="Submit for no, opens in a new window"
            >
              Update
            </button>
          
          </div>
        </form>
      </ng-container>
    </div>
  </ng-container>
</div>
