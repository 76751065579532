import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HomeService } from '../../../services/home/home.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-user-form',
  templateUrl: './create-user-form.component.html',
  styleUrls: ['./create-user-form.component.scss']
})
export class CreateUserFormComponent implements OnInit {
  public showLoader = false;
  public serverError = false;
  public featureList: Array<any>;
  public helpfulLinksList: Array<any>;
  private unsubscribe$ = new Subject();
  public createUser = false;
  public locationData: any;
  public locationIp: any;
  public locationCountry: any;

  constructor(
    public homeService: HomeService,
    private route: ActivatedRoute,
    private router: Router,
    public http: HttpClient
  ) { }

  ngOnInit(): void {
    //this.getLocationCountry();
    this.route.queryParams?.subscribe(
      (params: Params) => {
        if(this.router.url.indexOf('/home/create-user') > -1) {
          this.getHomeDataTask();
          this.createUser = true;
        }
      });
      this.homeService.data$.subscribe(
        (data) => {
          if (data === "createUserClick") {
            this.scrollContent();
          }
        }
      );
  }

  ngAfterViewInit(): void {
    this.scrollContent();
  }

  /**
   * Method to scroll the page to the create user form
   */
  public scrollContent(): void {
      const el: any = document.getElementById("create-user");
      el?.scrollIntoView();
  }

  /**
   * @description Method to get channelMap data from API
   */
  public getHomeDataTask (): void {
    this.homeService.getHomeData()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        if (resp) {
          const channelMapData = resp.channelList;
          this.featureList = resp?.featureList[0]?.Channel_List;
          this.helpfulLinksList = resp?.helpfulLinksList[0]?.Channel_List;
          //this.initData(channelMapData);
        }
      },
        (err) => {
          if (err?.error?.StatusCode === "500") {
            this.serverError = true;
          }
        });
  }

}
