<div class="create-sso-container">
    <form
      [formGroup]="createSsoUserForm"
      (ngSubmit)="createSsoUser()"
    >
      <div
        class="row card-border col-sm-12 col-md-12 card-padding pr-0 form-color"
      >
        <div
        *ngIf="createUser !== true"
          class="col-sm-12 text-xl semi-bold title-color pl-0"
        >
          Best way to get help
        </div>
          <alert class="alert-inf mt-2" type="info" *ngIf="showNotification" [dismissible]="true" (onClosed)="clearMessage()">
            <div class="d-flex flex-row bd-highlight align-alert">
              <div class="d-flex align-items-md-center align-items-sm-start pt-1">
                <img class="mr-2 mb-1 mt-1" height="20" width="20" src="../../../../assets/images/feature/info.png" alt="alert message info" />
                <div class="bd-highlight"><span>{{ alertMessage }}</span></div>
              </div>
            </div>
          </alert>
          <alert class="alert-err mt-2" *ngIf="showErrorMessage" type="error" [dismissible]="true" (onClosed)="clearMessage()">
            <div class="d-flex flex-row bd-highlight align-alert">
              <div class="d-flex align-items-md-center align-items-sm-start pt-1">
                <img class="mr-2 mb-1" height="20" width="20" src="../../../../assets/images/feature/error.png" alt="sso error" />
                <div class="bd-highlight"><span>{{ errorMessage }}</span></div>
              </div>
            </div>
          </alert>
          <div *ngIf="createUser !== true"
          class="col-xs-12 col-sm-12 text-md mt-3 text-color pl-0"
        >
          Complete this form to create a user login
        </div>
        <div *ngIf="createUser === true"
          class="col-xs-12 col-sm-12 text-xl text-color pl-0"
        >
          Complete this form to create a user login
        </div>
        <div class="col-sm-6 mt-3 pl-0">
          <div class="text-md text-color">First Name*</div>
          <div class="text-md mt-2">
            <input
              #firstNameField
              class="form-control input-box"
              type="text"
              formControlName="firstName"
              [ngClass]="{
                'invalid':
                  showValidation && submitted && f.firstName.errors
              }"
            />
          </div>
          <div
          *ngIf="showValidation && submitted && f.firstName.errors"
          class="danger-text"
        >
          <div *ngIf="f.firstName.errors.required">
            <img
                class="mb-1"
                height="20"
                width="20"
                src="../../../../assets/images/feature/error.png"
                alt="sso first name error"
              />
            First Name is required
          </div>
        </div>
        </div>
        <div class="col-xs-12 col-sm-6 mt-3 pl-0">
          <div class="text-md text-color">Last Name*</div>
          <div class="text-md mt-2">
            <input
              class="form-control input-box"
              type="text"
              formControlName="lastName"
              [ngClass]="{
                'invalid':
                  showValidation && submitted && f.lastName.errors
              }"
            />
          </div>
          <div
            *ngIf="showValidation && submitted && f.lastName.errors"
            class="danger-text"
          >
            <div *ngIf="f.lastName.errors.required">
              <img
                class="mb-1"
                height="20"
                width="20"
                src="../../../../assets/images/feature/error.png"
                alt="sso last name error"
              />
              Last Name is required
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 mt-3 pl-0">
          <div class="text-md text-color">Business Email Address*</div>
          <div class="text-md mt-2">
            <input
              class="form-control input-box"
              type="text"
              formControlName="emailID"
              [ngClass]="{
                'invalid':
                  showValidation && submitted && f.emailID.errors
              }"
            />
          </div>
          <div
            *ngIf="showValidation && submitted && f.emailID.errors"
            class="danger-text"
          >
            <div *ngIf="f.emailID.errors.required">
              <img
                class="mb-1"
                height="20"
                width="20"
                src="../../../../assets/images/feature/error.png"
                alt="sso email error"
              />
              Business Email Address is required
            </div>
            <div *ngIf="f.emailID.errors.email">
              <img
                class="mb-1"
                height="20"
                width="20"
                src="../../../../assets/images/feature/error.png"
                alt="sso valid email error"
              />
              Business Email Address must be a valid email address
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 mt-3 pl-0">
          <div class="text-md text-color">Telephone Number*</div>
          <ngx-intl-tel-input
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="false"
            [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.Name]"
            [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO[locationCountry] ? CountryISO[locationCountry] : CountryISO.UnitedStates"
            [maxLength]="15"
            [phoneValidation]="true"
            [separateDialCode]="true"
            [numberFormat]="PhoneNumberFormat.National"
            name="telephoneNumber"
            class="form-control form-control-padding input-box mt-2 telephone"
            formControlName="telephoneNumber"
            [ngClass]="{
              'invalid':
                showValidation &&
                submitted &&
                f.telephoneNumber.errors
            }"
          >
          </ngx-intl-tel-input>
          <div
            *ngIf="
              showValidation &&
              submitted &&
              f.telephoneNumber.errors
            "
            class="danger-text"
          >
            <div *ngIf="f.telephoneNumber.errors.required">
              <img
                class="mb-1"
                height="20"
                width="20"
                src="../../../../assets/images/feature/error.png"
                alt="sso phone error"
              />
              Telephone Number is required
            </div>
            <div
              *ngIf="
                !f.telephoneNumber.errors.required &&
                !f.telephoneNumber.valid
              "
            >
            <img
                class="mb-1"
                height="20"
                width="20"
                src="../../../../assets/images/feature/error.png"
                alt="sso valid phone error"
              />
              Telephone Number is not valid
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 mt-3 pl-0">
          <div class="text-md text-color">Company Name*</div>
          <div class="text-md mt-2">
            <input
              class="form-control input-box"
              type="text"
              formControlName="companyName"
              [ngClass]="{
                'invalid':
                  showValidation && submitted && f.companyName.errors
              }"
            />
          </div>
          <div
            *ngIf="showValidation && submitted && f.companyName.errors"
            class="danger-text"
          >
            <div *ngIf="f.companyName.errors.required">
              <img
                class="mb-1"
                height="20"
                width="20"
                src="../../../../assets/images/feature/error.png"
                alt="sso company name error"
              />
              Company Name is required
            </div>
          </div>
        </div>
        <div
          class="col-xs-12 col-sm-12 mt-3 mb-3 text-md pr-0 pl-0 d-flex justify-content-xs-start justify-content-md-end"
        >
          <button
            type="submit"
            class="btn btn-primary float-right btn-height search-button"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
</div>