<div *ngIf="showLoader" class="dnbi-loader-container">
  <div class="dnbi-loader-gif"></div>
  <img src="../assets/images/common/dnb-loading.gif" alt="loading indicator" />
</div>
<div *ngIf="!serverError" class="d-flex flex-column main-container">
  <div class="banner-container">
    <div class="frequent-title-color text-lg banner-font banner-margin text-center">
      Digital Service Center
    </div>
  </div>

  <app-help-topics [featureList]="featureList"></app-help-topics>

  <div class="create-user-container pt-0 pb-4" id="create-user">
    <div class="route-title back-navigation col-xs-12 col-sm-4 col-md-8 text-3xl pl-0">
      <span class="back-home" [routerLink]="['/home']" routerLinkActive="router-link-active">
        <img alt="Left Arrow" class="left-arrow-image" src="assets/images/feature/arrow_back.png" />
        Home
      </span>

    </div>
    <div class="col-12 col-md-8 content-border mt-3 mb-3 pt-3 pb-3 card-padding">
      <app-create-sso-user [createUser]="createUser"></app-create-sso-user>
    </div>

  </div>

  <app-helpful-links [channelMapDataHelfulLink]="helpfulLinksList"></app-helpful-links>
</div>