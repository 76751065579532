import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SharedRoutingModule } from "./shared-routing.module";
import { InputComponent } from "./components/input/input.component";
import { CaseStatusSectionComponent } from "./components/case-status-section/case-status-section.component";
import { ReactiveFormsModule } from "@angular/forms";
import { AlertModule } from "ngx-bootstrap/alert";

@NgModule({
	declarations: [ InputComponent, CaseStatusSectionComponent ],
	imports: [ CommonModule, SharedRoutingModule,
		ReactiveFormsModule, AlertModule ],
	exports: [ CaseStatusSectionComponent ]
})
export class SharedModule {}
