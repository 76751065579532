import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CreateUserFormComponent } from "./components/home/create-user-form/create-user-form.component";
import { HomeComponent } from "./components/home/home.component";

const routes: Routes = [
	{
		path: "",
		component: HomeComponent,
		children: []
	},
	{
		path: "create-user",
		component: CreateUserFormComponent,
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class UserRoutingModule {}
