import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, APP_INITIALIZER } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { CoreModule } from "./core/core.module";
import { UserModule } from "./feature/user/user.module";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TabsModule } from "ngx-bootstrap/tabs";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from "@angular/forms";
import { NgxUiLoaderModule, NgxUiLoaderHttpModule } from "ngx-ui-loader";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { JwtModule } from "@auth0/angular-jwt";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { GAEventService } from './shared/services/ga-service/ga-event.service';
import { DriftEventService } from './shared/services/drift-service/drift-event.service';
import { SearchModule } from "./feature/search/search.module";
import { ModalComponent } from "./core/components/modal/modal.component";
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faPaperPlane, faEdit, faUsersCog, faUserSecret, faAngleRight } from '@fortawesome/free-solid-svg-icons';

export function tokenGetter() {
  return localStorage.getItem("token");
}

@NgModule({
  declarations: [AppComponent, ModalComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    CoreModule,
    UserModule,
    HttpClientModule,
    TabsModule.forRoot(),
    ButtonsModule.forRoot(),
    NgSelectModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    NgxUiLoaderModule.forRoot({
      fastFadeOut: true,
    }),
    NgxUiLoaderHttpModule.forRoot({
      excludeRegexp: ["/ivr/five9", "/ivr/getUser", "/ivr/createUser", "/ivr/addProductAccess", 
      "/ivr/token", "/ivr/search/getTitles", "https://ipapi.co/json/", "/ivr/search/getAllSearchData",
      "ivr/search/getSearchData"],
      showForeground: true,
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        throwNoTokenError: false,
        allowedDomains: ["example.com"],
        disallowedRoutes: [""],
      },
    }),
    NgIdleKeepaliveModule.forRoot(),
    SearchModule,
    FontAwesomeModule
  ],
  providers: [
    DriftEventService,
    GAEventService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {constructor(library: FaIconLibrary) {
  library.addIcons(
    faPaperPlane,faEdit, faUsersCog, faUserSecret, faAngleRight
  );
}}
