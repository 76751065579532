import { Component, NgZone, OnInit } from "@angular/core";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { setTheme } from "ngx-bootstrap/utils";
import { CommonConstant } from "./shared/constants/common.constants";
import { AuthService } from "./shared/services/auth/auth.service";
import { CommonService } from "./shared/services/common/common.service";
import { GAEventService } from './shared/services/ga-service/ga-event.service';
import { DriftEventService } from './shared/services/drift-service/drift-event.service';
import { interval } from "rxjs";
import { distinctUntilChanged, filter, map, startWith, take, takeUntil, tap } from "rxjs/operators";
import { NavigationStart, Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  idleState = 'Not started.';
  timedOut = false;
  title = "digital-ivr-ui";
  showModal:string;
  navigationStart$ = this.router.events.pipe(
    filter(event => event instanceof NavigationStart),
    startWith(null), // Start with something, because the app doesn't fire this on appload, only on subsequent route changes
    tap(event => {
    }/* Place code to track NavigationStart here */),
  ).subscribe();

  constructor(private idle: Idle,
    private keepalive: Keepalive,
    private authService: AuthService,
    public commonService: CommonService,
    public driftService: DriftEventService,
    public gaService: GAEventService,
    private ngZone: NgZone,
    private router: Router
  ) {
    setTheme("bs4");
    this.ngZone.runOutsideAngular(() => {
      // Check very regularly to see if the pending macrotasks have all cleared
      interval(10)
        .pipe(
          startWith(0), // So that we don't initially wait
          // To prevent a memory leak on two closely times route changes, take until the next nav start
          // takeUntil(this.navigationStart$),
          // Turn the interval number into the current state of the zone
          map(() => !this.ngZone.hasPendingMacrotasks),
          // Don't emit until the zone state actually flips from `false` to `true`
          distinctUntilChanged(),
          // Filter out unstable event. Only emit once the state is stable again
          filter(stateStable => stateStable === true),
          // Complete the observable after it emits the first result
          take(1),
          tap(stateStable => {
            // FULLY RENDERED!!!!
            // Add code here to report Fully Rendered
            this.commonService.initQualtrics();
          })
        ).subscribe();
    });
  }

  ngOnInit (): void {
    this.initUserIdle();
    // For GA enable
    //sessionStorage.removeItem('locationData');
    this.driftService.init();
    this.gaService.init();
    if ('globalPrivacyControl' in navigator) {
      if (navigator.globalPrivacyControl && !this.checkCookie('globalPrivacyControlCookie')) {
        this.showModal= 'globalPrivacyControl';
      } else {
        this.showModal= '';
      }
    }
  }

  ngAfterViewInit (): void {
    this.driftService.init();
    this.gaService.init();
  }
  checkCookie(name: string) {
    var nameEQ = name + "="; // Create the name=value string prefix
    var ca = document.cookie.split(';'); // Split all cookies into an array
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1); // Trim leading spaces
        if (c.indexOf(nameEQ) === 0) return true; // Check if cookie starts with nameEQ
    }
    return false; // Cookie not found
}
  /**
   * @description Method to initiate user idle timout management
   */
  public initUserIdle (): void {
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(CommonConstant.idleTime);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(3);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      this.reset();
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Session timed out!';
      this.timedOut = true;
      if (this.authService.isAuthenticated()) {
        const prevTab = this.commonService.getStringFromSession("previousTab");
        this.authService.doLogout(prevTab);
      }
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
    });

    this.idle.onTimeoutWarning.subscribe((countdown: string) => {
      if (this.authService.isAuthenticated()) {
        this.idleState = 'User will get logged out in ' + countdown + ' seconds!'
      }
    });

    this.reset();
  }

  /**
   * @description Method to reset idle time
   */
  public reset (): void {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
}
