import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppUtil } from "../../../core/utils/utils";
import { HomeService } from "../../../feature/user/services/home/home.service";
import { UserService } from "../../../feature/user/services/user.service";
import { AuthService } from "../../services/auth/auth.service";
import { CommonService } from "../../services/common/common.service";

@Component({
  selector: "app-case-status-section",
  templateUrl: "./case-status-section.component.html",
  styleUrls: ["./case-status-section.component.scss"],
})
export class CaseStatusSectionComponent implements OnInit, OnDestroy {
  /**
   * Holds the subscription array to unsubscribe on destroy
   */
  private unsubscribe$ = new Subject();
  public caseForm: UntypedFormGroup;
  public showCaseDetailSection: boolean = false;
  public caseStatus: any;
  public reasonText: any;
  public serverErrormessage: string;
  public serverSuccessmessage: string;
  @Input() public selectedFinalData: any;
  public updateCaseForm: UntypedFormGroup;
  public displayForm = true;
  public needMoreInfoForm = true;
  public check:any;
  public isDUNSCheck: boolean;
  public records: any[];
  constructor(
    public homeService: HomeService,
    private formBuilder: UntypedFormBuilder,
    public commonService: CommonService,
    public userService: UserService,
    public authService: AuthService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.isDUNSCheck =
      this.selectedFinalData?.data?.subreason === "Get DUNS Status"
        ? true
        : false;
    this.showCaseDetailSection = false;
    this.serverSuccessmessage = "";
  
  }

  ngOnInit(): void {
    this.caseForm = this.formBuilder.group({
      caseNumber: [
        "",
        [Validators.required, Validators.pattern("^[2-9]{1}[0-9]{7}$")],
      ],
      emailID: [""],
    });
    this.updateCaseForm = this.formBuilder.group({
      isResolved: [""],
      reason: [""],
    });
    const caseNumber = AppUtil.getFromSession("caseNumber");
    const userData = this.userService.getUserData();
    if (userData && Object.keys(userData).length > 0) {
      this.caseForm.patchValue({
        emailID: userData["emailID"],
      });
    }
    if (caseNumber && Object.keys(caseNumber).length > 0) {
      this.caseForm.patchValue({
        caseNumber: caseNumber,
      });
    }
    this.caseForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        AppUtil.saveToSession("caseNumber", value?.caseNumber);
      });
  }

  /**
   * @description convenience getter for easy access to caseForm fields
   */
  get f() {
    return this.caseForm.controls;
  }

  /**
   * @description convenience getter for easy access to updateCaseForm fields
   */
  get g() {
    return this.updateCaseForm.controls;
  }

  public onCaseFormSubmit() {
    this.caseForm.value.isDUNSCheck = this.isDUNSCheck;
  const check=this.caseForm.value.isDUNSCheck;
    if(check ==true){this.displayForm = false; this.needMoreInfoForm = false;
      
    } else if(check==false) {this.displayForm = true;this.needMoreInfoForm = true; }
    this.homeService
      .getCaseStatus(this.caseForm.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.showCaseDetailSection = true;
          if (resp && resp["records"] && resp["records"].length > 0) {
            if (this.isDUNSCheck) {
            
              this.records = resp["records"];
            } else {
              
              this.caseStatus = resp["records"][0];
            }
          }
        },
        (err) => {
          this.serverErrormessage = err?.error?.details;
        }
      );
  }

  /**
   * Method to clear notification message in form
   */
  public clearMessage(): void {
    this.serverErrormessage = "";
  }

  /**
   * Update case status
   */
  public onUpdateCaseFormSubmit(): void {
    const formData = { ...this.caseForm.value, ...this.updateCaseForm.value };
    this.displayForm = false;
    this.homeService
      .updateCaseStatus(formData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.showCaseDetailSection = true;
          if (resp && resp["Status"]) {
            this.caseStatus.Status = resp["Status"];
            this.caseStatus.StatusMessage = resp["StatusMessage"];
            this.serverSuccessmessage =
              "Case status has been updated successfully";
          }
        },
        (err) => {
          this.serverSuccessmessage = "";
          this.serverErrormessage = err?.error?.details;
        }
      );
  }

  /**
   * @description Method to redirect to external URL
   */
  public redirectTo(url: any): void {
    let redirectUrl = url;
    if (this.authService.isAuthenticated()) {
      const userData = this.userService.getUserData();
      if (userData && !AppUtil.isEmptyObject(userData)) {
        redirectUrl = this.commonService.getAppendedUrl(url, userData);
      }
    }
    window.open(redirectUrl, "_blank");
  }

  /**
   * Unsubscribe the Observable
   */
  public ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }
}
