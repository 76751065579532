import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SearchService } from "../../../../search/services/search/search.service";
import { UserService } from "src/app/feature/user/services/user.service";
import { AppUtil } from "../../../../../core/utils/utils";

@Component({
  selector: "app-common-keywords",
  templateUrl: "./common-keywords.component.html",
  styleUrls: ["./common-keywords.component.scss"],
})
export class CommonKeywordsComponent implements OnInit {
  private unsubscribe$ = new Subject();
  public serverError = false;
  public searchedKeyword: string;
  public displayContent: string;
  public displayList: Array<any>;
  public searchResults: any;
  public keyword: any;

  constructor(
    private ngxService: NgxUiLoaderService,
    private searchService: SearchService,
    private route: ActivatedRoute,
    private uService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      if (this.router.url.indexOf("/search/common-keywords") > -1) {
        this.searchedKeyword = params["keyword"];
        this.getAllSearchTask(this.searchedKeyword);
        this.searchService.emitData({ data: true });
      }
    });
  }

  /**
   * @description Method to get all search data from API
   */
  public getAllSearchTask(keyword: string): void {
    this.ngxService.start();
    this.searchService
      .getAllSearchData(keyword)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          if (resp) {
            this.searchResults = resp;
          }
        },
        (err) => {
          this.ngxService.stop();
          if (err?.status == "400") {
            if (err?.error?.MessageCode === "002") {
              //   for GA
              this.uService.emitEvent(
                keyword,
                "Searched Keyword",
                "SearchMissing",
                8
              );
              // for GA
            } 
            else if(err?.error?.MessageCode === "003")
            {
              //   for GA
              this.uService.emitEvent(
                keyword,
                "Searched Keyword",
                "SearchSuccess",
                8
              );
              // for GA
            }

            this.displayContent = err?.error?.DisplayContent;
            this.displayList = err?.error?.DisplayList;
            this.keyword = err?.error?.keyword;
            this.serverError = true;
          }
        }
      );
  }

  /**
   * Method to remove html tags from a string, leaving only the inner text
   * @param str String contains HTML tags
   */
  public removeHTML(str: any): string {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
  }

  /**
   * Method to display search results for selected keyword
   * @param searchedKeyword
   */
  public showAllSearch(searchedResult: any): void {
    const currentTimeInSeconds = Math.floor(Date.now() / 1000); //unix timestamp in seconds
    const stringKeyword = this.removeHTML(searchedResult.list);
    if (
      stringKeyword === "Find a Company or Dun & Bradstreet's D-U-N-S Number?"
    ) {
       
 window.open(
      "https://www.dnb.com/site-search-results.html#AllSearch=" +
        this.keyword +
        "&CompanyProfilesPageNumber=1&CompanyProfilesSearch=" +
        this.keyword +
"ContactProfilesPageNumber=1&DAndBMarketplacePageNumber=1" +
    "&DAndBMarketplaceSearch="+this.keyword + "IndustryPageNumber=1&SiteContentPageNumber=1" +
    "&SiteContentSearch=" +
        this.keyword +"&tab=Company%20Profiles" ,
      "_blank"
    );
    } else if (stringKeyword === "Get a Status on an Existing Support Case") {
      AppUtil.saveToSession("caseNumber", this.searchedKeyword);
      this.router.navigate(["/search/article-content"], {
        queryParams: {
          keyword: stringKeyword,
          title: stringKeyword,
          id: currentTimeInSeconds,
        },
      });
    } else {
      this.router.navigate(["/search/search-results"], {
        queryParams: { keyword: stringKeyword, id: currentTimeInSeconds },
      });
    }
  }
}
