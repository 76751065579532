import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HomeService } from 'src/app/feature/user/services/home/home.service';
import { SearchService } from '../../services/search/search.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  public helpfulLinksList: Array<any>;
  public serverError = false;
  private unsubscribe$ = new Subject();

  constructor(
    public homeService: HomeService,
    public searchService: SearchService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getHomeDataTask();
    this.searchService.emitUrlData({url : this.router.url });
  }

  /**
   * @description Method to get channelMap data from API
   */
  public getHomeDataTask (): void {
    this.homeService.getHomeData()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        if (resp) {
          this.helpfulLinksList = resp?.helpfulLinksList[0]?.Channel_List;
        }
      },
        (err) => {
          if (err?.error?.StatusCode === "500") {
            this.serverError = true;
          }
        });
  }

}
