import { Injectable } from "@angular/core";
import { AppUtil } from "src/app/core/utils/utils";

declare let showIntercept: Function;


@Injectable({
  providedIn: "root"
})
export class CommonService {
  isNonSso = false;
  isInternal = false;
  appendUrl:string;

  constructor() { }

  /**
 * @description Method to get string data from session
 */
  public getStringFromSession (key: string): string {
    const item = AppUtil.getFromSession(key);
    const itemString = typeof item == "string"
      ? item
      : JSON.stringify(item);

    return itemString;
  }

  /**
  * @description Method to set Non SSO login flow true/false
  */
  public setNonSso (value: boolean): void {
    this.isNonSso = value;
  }

  /**
  * @description Method to get Non SSO login flow true/false
  */
  public getNonSso (): boolean {
    return this.isNonSso;
  }

  /**
  * @description Method to get whether logged In User is internal user or external user
  */
  public isInternalUser (): boolean {
    let returnValue = false;
    const userItem = AppUtil.getFromLocal("_user");
    const user = typeof userItem == "string"
      ? JSON.parse(userItem)
      : {};
    const email = user["emailID"] || "";
    if (AppUtil.isStringInString(email, "@dnb.com")) {
      returnValue = true;
    }
    return returnValue;
  }

  /**
* Method to get appended url with userdata
* @param url redirection url
* @param userData user data
*/
  public getAppendedUrl (url: string, userData: any): string {
    this.appendUrl = "";
    this.appendUrl =
      "&email=" + userData["emailID"] +
      "&fname=" + userData["firstName"] +
      "&lname=" + userData["lastName"];
    return url + this.appendUrl;
  }

  /**
  * @description Method to initialise qualtrics
  */
  public initQualtrics (): void {
    showIntercept();
  }
  
}
