import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Params, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppUtil } from 'src/app/core/utils/utils';
import { UserService } from 'src/app/feature/user/services/user.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(public authService: AuthService, public router: Router, public route: ActivatedRoute, public userService: UserService, private ngxService: NgxUiLoaderService) { }

  canActivate (): boolean {
    let code: string;
    let state: string;
    const redirectUrl: string = AppUtil.getBaseUrl();
    let returnvalue = true; // Set it false for login restricted pages
    if (!this.authService.isAuthenticated()) {
      this.route.queryParams.subscribe(params => {
        code = params['code'];
        state = params['state'];
        if (code && code !== "") {
          this.ngxService.start();
          this.userService.getLoginData(code, redirectUrl).subscribe((resp) => {
            this.ngxService.start();
            if (resp && resp['api_code'] === 'int_001') { // For internal users only
              window.location.href = resp.redirection_url;
            } else {
              const loginData = resp;
              AppUtil.saveToLocal("token", loginData.access_token);
              this.userService.setUserData(loginData);
              AppUtil.saveToLocal("licensedUser", "true");
              this.userService.loadDriftChat(AppUtil.getFromSession('driftDiv'))
              //For GA--adding dummy user
              this.userService.emitEvent("divrprodtest01@gmail.com", "SSO User Login", "provisioning", 4)
              //For GA
            }
            this.ngxService.stop();
          },
            (error) => {
              console.log("Get token error: ", error);
              this.ngxService.stop();

            },
            () => {
              this.ngxService.stop();
            }
          );
          const queryParams: Params = { TAB_ID: state !== "state" ? state : null, code: null, state: null };
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: queryParams,
            queryParamsHandling: "merge", // to replace all query params by provided
          });
        } else {
          // Code not found in URL
          returnvalue = true;
        }
      });
    }
    return returnvalue;
  }
}
