import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NotFoundComponent } from "./core/components/not-found/not-found.component";
import { AuthGuardService } from "./shared/services/auth/auth-guard.service";

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  {
    path: "home",
    loadChildren: () =>
      import("./feature/user/user.module").then((mod) => mod.UserModule),
      canActivate: [AuthGuardService]
  },
  {
    path: "search",
    loadChildren: () =>
      import("./feature/search/search.module").then((mod) => mod.SearchModule),
      canActivate: [AuthGuardService]
  },
  {
    path: "product",
    loadChildren: () =>
      import("./feature/product/product.module").then((mod) => mod.ProductModule),
      // canActivate: [AuthGuardService]
  },
  { path: "**", component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
