import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { Router } from "@angular/router";
import { AppUtil } from "src/app/core/utils/utils";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-drop-down-panel",
  templateUrl: "./drop-down-panel.component.html",
  styleUrls: ["./drop-down-panel.component.scss"],
})
export class DropDownPanelComponent implements OnInit {
  @Input("tabData") tabData: any;
  @Input("hoverData") hoverData: any;
  @Input() clearDropdown: Subject<boolean>;
  @Input() changeDropdown: Subject<boolean>;
  public firstDropData: any;
  public secondDropData: any;
  public thirdDropData: any;
  public channelList: any;
  public isShowSecondDrop = false;
  public isShowThirdDrop = false;
  public secondChannelList: any;
  public thirdChannelList: any;
  @Output() selectedOption: EventEmitter<any> = new EventEmitter();
  /**
   * Holds the subscription array to unsubscribe on destroy
   */
  private unsubscribe$ = new Subject();
  public tabDetails: any;
  public firstDrop: any;

  constructor(public router: Router) {}

  ngOnInit(): void {
    this.initData();
    if (this.clearDropdown) {
      this.clearDropdown.pipe(takeUntil(this.unsubscribe$)).subscribe((v) => {
        // this.clearDropDownValues();
      });
    }

    if (this.changeDropdown) {
      this.changeDropdown.pipe(takeUntil(this.unsubscribe$)).subscribe((v) => {
        this.initData();
      });
    }


    this.tabDetails = localStorage.getItem("tab_details");
    this.tabDetails = JSON.parse(this.tabDetails);
    if (this.tabDetails) {
      this.tabDetails.firstDrop = AppUtil.getFromSession("firstDrop");
      this.changeFirstOption(this.tabDetails.firstDrop);
      if (this.secondChannelList && this.secondChannelList?.length > 0) {
        this.selectedSecondOption(this.tabDetails.secondDrop)
      }
      if (this.secondDropData && this.thirdChannelList && this.thirdChannelList?.length > 0) {
        this.selectedThirdOption(this.tabDetails.thirdDrop)
      }
    }
  }

  ngAfterViewInit(): void {
    const comboboxElement =document.querySelectorAll('#first-dropdown div[role="combobox"]');
    comboboxElement.forEach((ele) => {
      ele?.setAttribute('aria-label','first-dropdown-item');
    });
    const inputElement = document.querySelectorAll('#first-dropdown input');
    inputElement.forEach((ele) => {
      ele?.setAttribute('aria-label','first-dropdown-input');
    });
  }
  
  /**
   * @description Method to initialize data
   */
  public initData() {
    this.channelList = this.tabData?.Channel_List;
    
    // console.log("tabData ",this.tabData);
    // console.log("secondChannelList ",this.secondChannelList);
    this.secondChannelList = this.tabData?.subreason;
    // console.log("secondChannelList ",this.secondChannelList);
    // const prevTabSession = AppUtil.getFromSession("previousTab");
    // const prevTab =
    //   "TAB_ID=" +
    //   (typeof prevTabSession == "string"
    //     ? prevTabSession
    //     : JSON.stringify(prevTabSession));
    //if (AppUtil.isStringInString(this.router.url, prevTab)) {
      // Same URL refresh page
      const firstDrop = AppUtil.getFromSession("firstDrop");
      if (firstDrop && Object.keys(firstDrop).length > 0) {
        this.selectedFirstOption(firstDrop);
      }
      const secondDrop = AppUtil.getFromSession("secondDrop");
      if (secondDrop && Object.keys(secondDrop).length > 0) {
        this.isShowSecondDrop = true;
        this.selectedSecondOption(secondDrop);
      }
      const thirdDrop = AppUtil.getFromSession("thirdDrop");
      if (thirdDrop && Object.keys(thirdDrop)?.length > 0) {
        this.isShowThirdDrop = true;
        this.selectedThirdOption(thirdDrop);
      } else {
        this.isShowSecondDrop = false;
        this.isShowThirdDrop = false;
      }
    //}
  }

  /**
   * @description Method to clear all dropdown values
   */
  public clearDropDownValues() {
    this.firstDropData = null;
    this.secondDropData = null;
    this.thirdDropData = null;
  }

  /**
   * @description Method to clear dropdown 2
   */
  public clearSecondDrop() {
    // this.secondDropData = null;
    // AppUtil.deleteSessionStorage("secondDrop");
    // AppUtil.deleteSessionStorage("secondDropOption");
  }

  /**
   * @description Method to clear dropdown 3
   */
  public clearThirdDrop() {
    // this.thirdDropData = null;
    // AppUtil.deleteSessionStorage("thirdDrop");
    // AppUtil.deleteSessionStorage("thirdDropOption");
  }


  // public clearData(): void {
  //   localStorage.removeItem("tab_details");
  // }

  /**
   * @description Method to trigger when dropdown one value changed
   */
  changeFirstOption(value: any) {
    this.clearSecondDrop();
    this.clearThirdDrop();
    this.selectedFirstOption(value);
  }

  /**
   * @description Method to trigger when dropdown one value changed
   */
  //  changeSecondOption(value: any) {
  //   // this.clearSecondDrop();
  //   this.clearThirdDrop();
  //   this.selectedSecondOption(value);
  // }

  /**
   * @description Method to get first dropdown option
   */
  selectedFirstOption(value: any) {
    this.firstDropData = value;
    AppUtil.getFromSession("firstDrop");
    AppUtil.getFromSession("firstDropOption");
    if(this.firstDropData){
      this.isShowSecondDrop =
      this.firstDropData?.hasSubreason &&
      this.firstDropData?.subreasons?.length > 0;
    }
    if (this.isShowSecondDrop) {
      this.secondChannelList = this.firstDropData?.subreasons;
      this.selectedOption.emit({ data: [], toShow: false });
    } else {
      this.secondChannelList = [];
      this.selectedOption.emit({ data: this.firstDropData, toShow: true });
    }
  }

  /**
   * @description Method to get second dropdown option
   */
  public selectedSecondOption(value: any) {
    // this.firstDropData = value;
    this.firstDrop = AppUtil.getFromSession("firstDrop");
    this.firstDropData = AppUtil.getFromSession("firstDropOption");
    if(this.firstDropData){
      this.isShowSecondDrop =
      this.firstDropData?.hasSubreason &&
      this.firstDropData?.subreasons?.length > 0;
    }
    if (this.isShowSecondDrop) {
      this.secondChannelList = this.firstDropData?.subreasons;
      this.selectedOption.emit({ data: [], toShow: false });
    } else {
      this.secondChannelList = [];
      this.selectedOption.emit({ data: this.firstDropData, toShow: true });
    }
    this.secondDropData = value;
    this.thirdDropData = null;
    AppUtil.saveToSession("secondDrop", this.secondDropData);
    AppUtil.saveToSession("secondDropOption", this.secondDropData?.subreason);
    this.isShowThirdDrop =
      this.secondDropData?.hasSubreason &&
      this.secondDropData?.subreasons?.length > 0;
    if (this.isShowThirdDrop) {
      this.thirdChannelList = this.secondDropData?.subreasons;
      this.selectedOption.emit({ data: [], toShow: false });
    } else {
      this.thirdChannelList = [];
      this.selectedOption.emit({ data: this.secondDropData, toShow: true });
    }
  }

  /**
   * @description Method to get third dropdown option
   */
  public selectedThirdOption(value: any) {
    this.thirdDropData = value;
    AppUtil.saveToSession("thirdDrop", this.thirdDropData);
    AppUtil.saveToSession("thirdDropOption", this.thirdDropData?.subreason);
    this.selectedOption.emit({ data: this.thirdDropData, toShow: true });
  }

  /**
   * Unsubscribe the Observable
   */
  // public ngOnDestroy(): void {
  //   this.unsubscribe$.next(undefined);
  //   this.unsubscribe$.complete();
  // }
}
