import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AccordionPanelComponent } from 'ngx-bootstrap/accordion';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from '../../../../../shared/services/common/common.service';
import { HomeService } from '../../../services/home/home.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  public faqContent: Array<any> = [];
  public finalData: any;
  @Input() selectedFinalData: any;
  @Input() dykData: any;
  @Input() selectedTab: any;
  @Input() selectedReasonDetails: any;
  public thumbUpImageSrc: any;
  public thumbDownImageSrc: any;
  public isOpened: boolean;
  @ViewChild('group1', { static: false }) group1: AccordionPanelComponent;
  public reasonDetails: any;
  public isReasonDetails: Subject<boolean> = new Subject();
  private unsubscribe$ = new Subject();
  public isFaqContent: Subject<boolean> = new Subject();
  public clickedTitle: any;
  public titleId: any;

  constructor(
    public homeService: HomeService, 
    public commonService: CommonService,
    public uService: UserService
  ) {}

  ngOnInit (): void {
    this.faqData();
  }

  ngOnChanges(): void {
    this.finalData = this.selectedFinalData;
    this.reasonDetails = this.selectedReasonDetails;
    this.faqData();
  }

  ngAfterViewInit(): void {
    this.clickEvent();
  }

  public clickEvent(): void {
    this.isFaqContent
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((v) => {
        const faqId = "faq-content-" + this.selectedTab?.TAB_ID;
        const faqContent = document.getElementById(faqId);
        const anchor = faqContent?.getElementsByTagName("a");
        const arr = anchor ? Array.from(anchor) : [];
        for (let i = 0; arr && i < arr?.length; i++) {
          arr[i].removeEventListener('click', this.handleAnchorClick.bind(this));
          arr[i].addEventListener('click', this.handleAnchorClick.bind(this, arr[i]));
        }
      });
  }

  /**
   * Method to handle click in article content anchor tags
   */
  public handleAnchorClick(linkDetails: any): void {
    const sfdcLink = linkDetails?.getAttribute("href");
    // for GA
    this.clickedTitle = linkDetails?.innerHTML?.replace('&nbsp;', ' ').replace("<u>", " ").replace("</u>", " ")
    .replace('&amp;', '&');
    const heading = document.getElementById(this.titleId)?.innerHTML?.replace('&amp;', '&');
    // for GA
    this.uService.emitEvent(this.selectedTab.TAB_NAME + ">>>" + heading, this.selectedTab.TAB_NAME + ">>>" + heading + ">>>" + this.clickedTitle + ">>>" + sfdcLink, "faqArticle", 13);
    // for GA
  }

  /**
   * @description Method to display faq data
   */
  public faqData (): void {
    this.faqContent = [];
    if(this.finalData) {
      if (this.finalData?.data?.hasFaq === true && this.finalData?.data?.faqDetails?.length > 0 && this.dykData?.length > 0) {
        for (let j = 0; j < this.finalData?.data?.faqDetails?.length; j++) {
          for (let i = 0; i < this.dykData?.length; i++) {
            if (this.dykData[i]?.Title === this.finalData?.data?.faqDetails[j]?.faqTitle) {
              this.faqContent.push({
                title: this.finalData?.data?.faqDetails[j]?.faqTitle,
                content: this.dykData[i]?.Content
              });
            }
          }
        }
      }
    } else if (this.reasonDetails) {
      if (this.reasonDetails?.hasFaq === true && this.dykData?.length > 0) {
        for (let i = 0; i < this.dykData?.length; i++) {
          if (this.reasonDetails?.faqTitle1) {
            if (this.dykData[i]?.Title === this.reasonDetails?.faqTitle1) {
              this.faqContent.push({
                title: this.reasonDetails?.faqTitle1,
                content: this.dykData[i]?.Content
              });
            }
          } if (this.reasonDetails?.faqTitle2) {
            if (this.dykData[i]?.Title === this.reasonDetails?.faqTitle2) {
              this.faqContent.push({
                title: this.reasonDetails?.faqTitle2,
                content: this.dykData[i]?.Content
              });
            }
          } if (this.reasonDetails?.faqTitle3) {
            if (this.dykData[i]?.Title === this.reasonDetails?.faqTitle3) {
              this.faqContent.push({
                title: this.reasonDetails?.faqTitle3,
                content: this.dykData[i]?.Content
              });
            }
          }
        }
      }
    }
    setTimeout(() => {
      this.isFaqContent.next(true);
    }, 1500);
  }

  /**
   * Method to get the accordion open change
   * @param event Event
   */
  public accordionEvent(event: any, i: any): void {
    if (event === true) {
      this.titleId = "faq-title-" + this.selectedTab?.TAB_ID + "-0" + (i+1);
    }
  }

  /**
   * Method to toggle accordion
   */
  public toggleAccordion (): void {
    this.commonService.initQualtrics();
  }
}
