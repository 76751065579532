import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SERVICE_GLOBAL } from 'src/app/shared/constants/services.global';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public serviceGlobal: any;
  private data = new Subject<any>();
  public data$ = this.data.asObservable();
  private urlData = new Subject<any>();
  public urlData$ = this.urlData.asObservable();
  
  constructor(private http: HttpClient, private injector: Injector) {
    this.serviceGlobal = this.injector.get(SERVICE_GLOBAL);
  }

  /**
	 * @description API call to get titles for search box
	 */
  public getTitles (keyword: string): Observable<any> {
    const body = {
      "keyword": keyword
    }
    return this.http.post(this.serviceGlobal.GET_TITLES, body);
  }

  /**
	 * @description API call to get search data
	 */
  public getSearchData (keyword: string, title: string): Observable<any> {
    const body = {
      "keyword": keyword,
      "title": title
    }
    return this.http.post(this.serviceGlobal.GET_SEARCH_DATA, body);
  }

  /**
	 * @description API call to get search data
	 */
  public getAllSearchData (keyword: string): Observable<any> {
    const body = {
      "keyword": keyword
    }
    return this.http.post(this.serviceGlobal.GET_ALL_SEARCH_DATA, body);
  }

  /**
	 * @description API call to get tab details
	 */
  public getTabDetails(title: string): Observable<any> {
    const body = {
      "SearchTitle": title
    }
    return this.http.post(this.serviceGlobal.GET_TAB_DETAILS, body);
  }

/**
 * Method to emit the data from one component to another
 * @param data 
 */
  public emitData(data: any): void {
    this.data.next(data);
  }

  /**
 * Method to emit the data from one component to another
 * @param data 
 */
public emitUrlData(data: any): void {
  this.urlData.next(data);
}
}