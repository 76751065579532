import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { catchError, concatMap, retry, retryWhen } from "rxjs/operators";
import {
	HttpHandler,
	HttpRequest,
	HttpErrorResponse,
	HttpEvent,
	HTTP_INTERCEPTORS
} from "@angular/common/http";
import { environment } from "src/environments/environment";
@Injectable({
	providedIn: "root"
})
export class InterceptorService {
	constructor() {}

	public intercept(
		req: HttpRequest<object>,
		next: HttpHandler
	): Observable<HttpEvent<object>> {
		const token = window.localStorage.getItem("token");

		if (token) {
			req = req.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json;charset=UTF-8",
					Accept: "application/json"
				}
			});
		} else {
			req = req.clone({
				setHeaders: {
					"Content-Type": "application/json;charset=UTF-8",
					Accept: "application/json"
				}
			});
		}


		if (environment.useProxy && req.url.indexOf("/") > 0) {
			const proxyUrl = this.getProxyUrl(req.url);
			req = req.clone({ url: proxyUrl });
		}


		return next.handle(req).pipe(
			// retry(2),
			retryWhen(error =>
				error.pipe(
					concatMap((error, count) => {
						if (count < 2 && error.status !== 400) {
							return of(error);
						}
						return throwError(error);
					}),
				)
			),
			catchError((err: HttpErrorResponse) => {
				// 401 UNAUTHORIZED
				if (err && err.status === 401) {
					return throwError("You are not authorized. Please contact admin.");
				}
				return throwError(err);
			})
		);

	}

	public getProxyUrl(url: string): string {
		const replaceUrl = url.match(/^https?:\/\/[^#?/]+/);
		let proxyUrl = "/proxy";
		if (replaceUrl) {
			const apiUrl = url.replace(replaceUrl[0], "");
			if (
				environment.useProxy &&
				url.indexOf(environment.apiUrl) >= 0 &&
				!environment.enableMock
			) {
				proxyUrl = "/proxy" + apiUrl;
			} else if (environment.enableMock) {
				proxyUrl = environment.mockUrl + apiUrl;
			} else {
				proxyUrl = environment.apiUrl + apiUrl;
			}
		} else {
			proxyUrl = url;
		}
		return proxyUrl;
	}
}

export const InterceptorProviders = [
	{ provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }
];
