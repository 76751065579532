import { HttpClient } from '@angular/common/http';
import { Component, Input, KeyValueDiffer, KeyValueDiffers, OnChanges, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HomeService } from '../../../services/home/home.service';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { UserService } from '../../../services/user.service';
import { AppUtil } from 'src/app/core/utils/utils';

@Component({
  selector: 'app-create-sso-user',
  templateUrl: './create-sso-user.component.html',
  styleUrls: ['./create-sso-user.component.scss']
})
export class CreateSsoUserComponent implements OnInit, OnChanges {
  public createSsoUserForm: UntypedFormGroup;
  public showValidation = false;
  public locationCountry: any;
  public SearchCountryField = SearchCountryField;
  public CountryISO = CountryISO;
  public PhoneNumberFormat = PhoneNumberFormat;
  public preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  public submitted = false;
  private unsubscribe$ = new Subject();
  public phoneDetails: any;
  public alertMessage: string;
  public showNotification = false;
  public formData: any;
  public formattedNumber: number;
  @Input() finalData: any;
  private finalDataDiffer: KeyValueDiffer<string, any>
  public errorMessage: string;
  public showErrorMessage = false;
  @Input() locationCountryData: any;
  @Input() createUser: any;
  public locationCountryDetails: any;

  constructor( private formBuilder: UntypedFormBuilder, public homeService: HomeService,
    public http: HttpClient, private differs: KeyValueDiffers, private ngxService: NgxUiLoaderService,
    public userService: UserService) { 
    this.createSsoUserForm = this.formBuilder.group({
      firstName: new UntypedFormControl("", Validators.required),
      lastName: new UntypedFormControl("", Validators.required),
      emailID: new UntypedFormControl("", [Validators.required, Validators.email]),
      telephoneNumber: new UntypedFormControl("", Validators.required),
      companyName: new UntypedFormControl("", Validators.required),
    });
  }

  ngOnChanges(): void {
    // To find any difference in dropdown final data
    if (this.finalData) {
      this.finalDataDiffer = this.differs.find(this.finalData).create();
      const changes = this.finalDataDiffer.diff(this.finalData);
        if (changes) {
          this.showValidation  = false;
          this.clearMessage();
          this.resetCreateSsoForm();
        }
    }
  }

  ngOnInit(): void {
    //this.locationCountry = this.locationCountryData;
    this.locationCountryDetails = AppUtil.getFromSession("locationData");
    this.locationCountry = this.locationCountryDetails?.country_name ? this.locationCountryDetails?.country_name.replace(/\s/g, "") : "UnitedStates";
    this.homeService.data$.subscribe(
      (data) => {
        if (data === "createUserClick") {
          this.clearFormData();
        }
      }
    );
  }

  /**
  * @description convenience getter for easy access to form fields
  */
  get f () {
    return this.createSsoUserForm.controls;
  }

  /**
   * @description Method to create SSO user
   */
  public createSsoUser(): void {
    this.submitted = true;
    this.showNotification = false;
    this.clearMessage();
    if (this.createSsoUserForm.invalid) {
      this.showValidation = true;
      return;
    } else {
      this.formData = this.createSsoUserForm.value;
      this.phoneDetails = this.formData?.telephoneNumber;
      const number = this.phoneDetails?.e164Number.replace(
        this.phoneDetails?.dialCode,
        ""
      );
      this.formData.companyName = this.formData?.companyName ? this.formData?.companyName.replaceAll("&", "%26") : null;
      this.ngxService.start();
      this.homeService
        .getUser(this.formData.emailID)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (resp) => {
            this.clearFormData();
            if (resp && resp?.api_code === "001") {
              // Existing user with service.dnb.com access
              this.showNotification = true;
              this.alertMessage = resp?.message;
              this.ngxService.stop();
            } else if (resp && resp?.api_code === "002") {
              // Add the access for service.dnb.com for user who already registered with dnb but no access of service.dnb.com
              this.homeService
                .addProductAccess(this.formData.emailID)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(
                  (resp) => {
                    this.showNotification = true;
                    this.alertMessage = resp?.message;
                    //For GA
                    this.userService.emitEvent("divrprodtest01@gmail.com", "Add Product Access", "provisioning", 10)
                    //For GA
                    this.ngxService.stop();
                  }
                );
            } else if (resp && resp?.api_code === "003") {
              // Create new user
              this.homeService
                .createSsoUser(this.formData, number)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(
                  (resp) => {
                    this.showNotification = true;
                    this.clearFormData();
                    this.alertMessage = resp?.message;
                    //For GA
                    this.userService.emitEvent("divrprodtest01@gmail.com", "Create SSO User", "provisioning", 9)
                    //For GA
                    this.ngxService.stop();
                  },
                  (err) => {
                    if (err && err?.error?.code === "400003") { //User already exists in the D&B SSO system
                      this.showNotification = true;
                      this.clearFormData();
                      this.alertMessage = err?.error?.message;
                      this.ngxService.stop();
                    } else if (err && err?.error?.code === "403") { //User from domain: dnb.com cannot be created through this system
                      this.showErrorMessage = true;
                      this.showNotification = false;
                      this.clearFormData();
                      this.errorMessage = err?.error?.description;
                      this.ngxService.stop();
                    }
                  }
                );
            }
          }, (err) => {
            if (err) {
              this.ngxService.stop();
            }
          }
        );
    }
  }

  /**
   * @description Method to load form data
   */
  public loadFormData(): void {
      this.createSsoUserForm.setValue({
        firstName: null,
        lastName: null,
        emailID: null,
        telephoneNumber: null,
        companyName: null,
      });
  }

  /**
   * @description Method to clear telephone number field
   */
  public clearFormData(): void {
    this.showValidation = false;
    this.loadFormData();
  }

  /**
   * Method to clear messages
   */
  public clearMessage(): void {
    this.alertMessage = "";
    this.errorMessage = "";
    this.showNotification = false;
    this.showErrorMessage = false;
  }

  /**
   * Method to reset form
   */
  public resetCreateSsoForm(): void {
    this.createSsoUserForm.setValue({
      firstName: null,
      lastName: null,
      emailID: null,
      telephoneNumber: null,
      companyName: null,
    });
  }

  /**
  * Unsubscribe the Observable
  */
   public ngOnDestroy (): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

}
