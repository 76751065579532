import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SearchRoutingModule } from './search-routing.module';
import { SearchComponent } from './components/search/search.component';
import { ArticleContentComponent } from './components/search/article-content/article-content.component';
import { UserModule } from '../user/user.module';
import { PreviousRouteService } from '../user/services/common/previous-route.service';
import { SearchResultsComponent } from './components/search/search-results/search-results.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonKeywordsComponent } from './components/search/common-keywords/common-keywords.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [SearchComponent, ArticleContentComponent, SearchResultsComponent, CommonKeywordsComponent],
  imports: [
    CommonModule,
    SearchRoutingModule,
    UserModule,
    NgxIntlTelInputModule,
    FormsModule,
    ReactiveFormsModule,
    PopoverModule.forRoot(),
    SharedModule
  ],
  providers: [PreviousRouteService]
})
export class SearchModule { }
