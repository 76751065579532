<div class="helpful-links-container pb-4 m-0">
  <div class="helpful-links align-content pt-3">
    <div class="text-xl semi-bold helpful-heading">
        Helpful Links
    </div>
    <div class="d-flex flex-column flex-sm-row flex-wrap text-nm link-color" >
        <div class="col-xs-12 col-sm-6 col-md-4 line-space"  *ngFor="
        let subData of channelMapDataHelfulLink;
        let j = index">
         <a (click)="passGAParam(subData)" [id]="getHlNameId(subData?.HL_Name)" href="{{ (subData?.HL_URL)}}" target="_blank" rel="noopener noreferrer" [attr.aria-label]="(subData?.HL_Name)+', opens in a new window '">{{ (subData?.HL_Name) }}<img
          height="24"
          width="24"
          src="../../../../assets/images/feature/Link_arrow.png"
          alt="update"
        /></a>
        </div>
    </div>
</div>
</div>
