import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../services/home/home.service";
import { HelpfulLinks } from "src/app/shared/interfaces/channel-map-helpful";
import { UserService } from "../../../services/user.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-helpful-links",
  templateUrl: "./helpful-links.component.html",
  styleUrls: ["./helpful-links.component.scss"],
})
export class HelpfulLinksComponent implements OnInit {
  /**
   * Holds the subscription array to unsubscribe on destroy
   */
  private unsubscribe$ = new Subject();

  @Input("channelMapDataHelfulLink")
  channelMapDataHelfulLink: Array<HelpfulLinks>;

  constructor(
    public homeService: HomeService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    // this.getHomeDataTask();
  }

  /**
   * @description Method to get channelMap data from API
   */
  public getHomeDataTask(): void {
    this.homeService
      .getHomeData()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        if (resp && resp.helpfulLinksList[0]) {
          this.channelMapDataHelfulLink =
            resp?.helpfulLinksList[0]?.Channel_List;
        }
      });
  }

  /**
   * @description Method to pass values to Google Analytics
   */
  public passGAParam(helpfulLink: any) {
    //For GA
    this.userService.emitEvent(
      helpfulLink?.HL_URL,
      "Helpful Links",
      helpfulLink?.HL_Name,
      5
    );
    //For GA
  }

  /**
   * @description Method to generate id to hl name
   * @param name 
   */
  public getHlNameId(name: string | undefined ): string {
    return name ? name.toLowerCase().replace(/\s/g, '') : "noidfound";
  }

  /**
   * Unsubscribe the Observable
   */
  public ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }
}
