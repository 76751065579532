import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ArticleContentComponent } from './components/search/article-content/article-content.component';
import { CommonKeywordsComponent } from './components/search/common-keywords/common-keywords.component';
import { SearchResultsComponent } from './components/search/search-results/search-results.component';
import { SearchComponent } from './components/search/search.component';

const routes: Routes = [
	{
		path: "",
		component: SearchComponent,
		children: [
			{
				path: "article-content",
				component: ArticleContentComponent,
			},
			{
				path: "search-results",
				component: SearchResultsComponent,
			},
			{
				path: "common-keywords",
				component: CommonKeywordsComponent,
			}
		]
	}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SearchRoutingModule { }
