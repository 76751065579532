<div
  class="col-12 col-md-8 content-border mt-3 pt-3 pb-3 card-padding d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center">
  <div class="title-text text-xl pb-2">Best way to get help
  </div>
  <div>
    <button type="button" class="btn btn-primary btn-height" (click)="redirectTo()">
      Submit Case
    </button>
  </div>
</div>
