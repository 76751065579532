import { HttpClient } from '@angular/common/http';
import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppUtil } from 'src/app/core/utils/utils';
import { SERVICE_GLOBAL } from 'src/app/shared/constants/services.global';
import { GAEventService } from 'src/app/shared/services/ga-service/ga-event.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public serviceGlobal: any;
  public userData: any;
  public driftDiv: any;

  constructor(public router: Router, private http: HttpClient, private injector: Injector, public gaEventService: GAEventService) {
    this.serviceGlobal = this.injector.get(SERVICE_GLOBAL);
  }

  /**
* @description Method to do login action
*/
  public doLogin (stateParam?: string): void {
    const client_id: string = AppUtil.getOktaClientID();
    const response_type = "code";
    const scope = "openid profile";
    let state = "state";
    state = (stateParam && stateParam != "{}") ? stateParam : "state";

    window.location.href = this.serviceGlobal.OKTA_AUTHORIZE
      + "?client_id=" + client_id
      + "&redirect_uri=" + AppUtil.getBaseUrl()
      + "&response_type=" + response_type
      + "&scope=" + scope
      + "&state=" + state;
    localStorage.removeItem("nonSsoUser");
  }

  /**
   * @description API call to get home tab data
   * @param code Code getting from okta after login
   * @param redirectUrl Url to redirect after login
   */
  public getLoginData (code: string, redirectUrl: string): Observable<any> {
    const param = "?code=" + code + "&redirectUrl=" + redirectUrl;
    return this.http.get(this.serviceGlobal.LOGIN + param);
  }

  /**
 * @description API call to logout user
 * @param idToken ID Token
 * @param redirectUrl Redirect URL
 * @param state State parameter string to keep after redirection
 */
  public doLogout (idToken: string, redirectUrl: string, state: string): void {
    const param = "?id_token_hint=" + idToken
      + "&post_logout_redirect_uri=" + redirectUrl;
    // + "&state=" + state;
    const url = this.serviceGlobal.OKTA_LOGOUT + param;
    window.location.href = url;
  }


  /**
   * @description Method to set User data
   * @param userData User data
   */
  public setUserData (userData: any): void {
    AppUtil.saveToLocal("_user", userData);
  }

  /**
   * @description Method to get User data
   * @returns userData User data
   */
  public getUserData (): any {
    let userData = AppUtil.getFromLocal("_user");
    userData = typeof userData == "string"
      ? JSON.parse(userData)
      : userData;
    if (userData && !AppUtil.isEmptyObject(userData)) {
      return userData;
    } else {
      return {};
    }
  }

  /**
   * @description Method to set User token
   * @param userData User data
   */
   public setUserToken (userData: any): void {
    AppUtil.saveToLocal("token", userData);
  }

  /**
    * @description Method to get load drift chat
    */
  public loadDriftChat (divEle: any): void {
    if (divEle) {
      const el: any = document.getElementById(divEle);
      el.click();
    }
  }

  /**
  * @description Method to load drift playbook
  */
  public loadDriftPlaybook (chatEle: any): void {
    if (chatEle) {
      chatEle.click();
    }
  }

  /**
  * @description Method to hide drift chat
  */
  public hideDriftChat (hideEle: any): void {
    if (hideEle) {
      hideEle.click();
    }
  }

  /**
  * @description Method to logout drift
  */
  public logoutDrift (ele: any): void {
    if (ele) {
      const el: any = document.getElementById(ele);
      el.click();
    }
  }

  public emitEvent (eventCategory: any, eventAction: any, eventLabel: any, eventValue: number) {
    this.gaEventService.emitEvent(eventCategory, eventAction, eventLabel, eventValue)
  }
}
