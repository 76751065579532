import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppUtil } from 'src/app/core/utils/utils';
import { UserService } from 'src/app/feature/user/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public userData: any;
  constructor(
    public jwtHelper: JwtHelperService,
    public userService: UserService
  ) {
  }

  /**
  * @description Method to check whether user is authenticated or not.
  * Check whether the token is expired and return
  */
  public isAuthenticated (): boolean {
    let returnValue = false;
    let token = (AppUtil.getFromLocal('token'));
    if (token && !AppUtil.isEmptyObject(token)) {
      token = token.toString();
    } else {
      token = "";
    }

    if (token && token !== "") {
      const decodedToken = this.jwtHelper.decodeToken(token);
      const expirationDate = this.jwtHelper.getTokenExpirationDate(token);
      const isExpired = this.jwtHelper.isTokenExpired(token);
      if (isExpired) {
        this.clearLoginInfo();
      }
      returnValue = !isExpired;
    } else {
      // returnValue = false;
      // this.clearLoginInfo();
    }
    return returnValue;
  }

  /**
   * @description Method to do logout action
   */
  public doLogout (redirectionState: string): void {
    //Logout logic
    const userData = this.userService.getUserData();
    const idToken = userData.id_token;
    const redirectUrl = AppUtil.getBaseUrl();
    this.userService.logoutDrift(AppUtil.getFromSession('logoutDrift'));
    this.clearLoginInfo();
    this.userService.doLogout(idToken, redirectUrl, redirectionState);
  }

  /**
 * @description Method to clear all user login info stored
 */
  public clearLoginInfo (): void {
    AppUtil.deleteLocalStorage("token");
    AppUtil.deleteLocalStorage("_user");
  }

}
