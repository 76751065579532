import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CoreRoutingModule } from "./core-routing.module";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { LandingComponent } from "./components/landing/landing.component";
import { InterceptorProviders } from './services/interceptor/interceptor.service';
import { PopoverModule } from "ngx-bootstrap/popover";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AlertModule } from "ngx-bootstrap/alert";

@NgModule({
	declarations: [
		HeaderComponent,
		FooterComponent,
		NotFoundComponent,
		LandingComponent
	],
	imports: [CommonModule, CoreRoutingModule, PopoverModule.forRoot(), BrowserAnimationsModule, 
		BsDropdownModule.forRoot(), AlertModule.forRoot()],
  exports: [HeaderComponent, FooterComponent],
  providers: [
    InterceptorProviders
  ]
})
export class CoreModule {}
