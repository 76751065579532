<div class="footer-container text-sm">
  <nav class="footer mb-0" id="theFooter">
    <div class="footer-margin d-flex flex-column flex-sm-row">
      <div class="col-sm-6 pl-0 pr-0">United States : <span title="en-us">English</span>
        <div class="text-nowrap footer-link">© <a href="https://www.dnb.com/" target="_blank" rel="noopener noreferrer" aria-label="Dun & Bradstreet,
          Inc., opens in a new window">Dun &amp; Bradstreet,
            Inc.</a>
          2000-{{currentYear}}. All rights reserved. </div>
      </div>
      <div class="col-sm-6 pl-0 pr-0">
        <div class="footer-link d-flex justify-content-lg-end justify-content-md-end justify-content-sm-start">
          <a class="text-nowrap" href="https://www.dnb.com/utility-pages/privacy-policy.html" target="_blank" rel="noopener noreferrer" aria-label="Privacy
          Policy, opens in a new window">Privacy
            Policy |</a>
          <a class="text-nowrap footer-terms" href="https://www.dnb.com/utility-pages/terms-of-use.html"
            target="_blank" rel="noopener noreferrer" aria-label="Terms of Use, opens in a new window">Terms of Use</a>
        </div>
      </div>
    </div>
  </nav>
</div>
