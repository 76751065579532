import { Component, OnInit, Input } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { Subject } from 'rxjs';
import { AppUtil } from 'src/app/core/utils/utils';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { CommonService } from 'src/app/shared/services/common/common.service';
import { HomeService } from '../../../services/home/home.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-help-topics',
  templateUrl: './help-topics.component.html',
  styleUrls: ['./help-topics.component.scss']
})
export class HelpTopicsComponent implements OnInit {

  @Input("featureList") featureList: any;
  public icons: IconName[] = [];
  // featureList: any;
  /**
  * Holds the subscription array to unsubscribe on destroy
  */
  private unsubscribe$ = new Subject();

  constructor(public homeService: HomeService, public userService: UserService, public commonService: CommonService, public authService: AuthService) { }

  ngOnInit (): void {
    this.icons = ['paper-plane', 'edit', 'users-cog', 'user-secret'];
  }

  /**
* @description Method to redirect to external URL
*/
  public redirectTo (fList: any): void {
    this.passGAParam(fList); // Pass values to Google Analytics
    const url = fList?.FL_URL ? fList?.FL_URL : "";
    let redirectUrl = url;
    if (fList?.sendCustomerDetails && this.authService.isAuthenticated()) {
      const userData = this.userService.getUserData();
      if (userData && !AppUtil.isEmptyObject(userData)) {
        redirectUrl = this.commonService.getAppendedUrl(url, userData);
      }
    }
    window.open(
      redirectUrl,
      '_blank'
    );
  }

  /**
   * @description Method to pass values to Google Analytics
   */
  public passGAParam (helpfulLink: any) {
    //For GA
    this.userService.emitEvent(helpfulLink?.FL_URL, "Helptopic Links", helpfulLink?.FL_Name, 6)
    //For GA
  }

  /**
    * Unsubscribe the Observable
    */
  public ngOnDestroy (): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

}
