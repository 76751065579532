import { OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: "app-modal",
	templateUrl: "./modal.component.html",
	styleUrls: ["./modal.component.scss"]
})
export class ModalComponent implements OnInit {
	constructor( public router: Router) {}
	@Input() modalPopupName: string = '';
	@Output() modalPopupNameChange = new EventEmitter<string>();
	ngOnInit(): void {	
	}

	externalNavigation(data: any) {
		if(data == 'privacy_choices') {
		    window.open("https://submit-irm.trustarc.eu/services/validation/ba81b98f-997d-4216-b4cc-d64cf261b082", "_blank");

		} else if(data == 'cookie_policy'){
			window.open("https://www.dnb.com/content/dam/english/dnb-solutions/DB_Cookie_Policy_Statement_Version.pdf", "_blank");
		}

	}

	closeModal() {
		this.modalPopupName = '';
		this.modalPopupNameChange.emit(this.modalPopupName);
		this.setCookie("globalPrivacyControlCookie", "gpcValue", 365);

	  }

	setCookie(name:string, value:string, days:number) {
		console.log('set cookies')
		var expires = "";
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)); // Calculate expiration date
			expires = "; expires=" + date.toUTCString(); // Convert date to UTC string
		}
		document.cookie = name + "=" + (value || "") + expires + "; path=/"; // Set the cookie
	}
	
	// Usage: Set a cookie named "myCookie" with the value "testValue" that expires in 365 days
}