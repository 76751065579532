import { Component, Input, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { UserService } from '../../../services/user.service';
import { AppUtil } from 'src/app/core/utils/utils';
import { CommonConstant } from 'src/app/shared/constants/common.constants';
import { CommonService } from 'src/app/shared/services/common/common.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-login-section',
  templateUrl: './login-section.component.html',
  styleUrls: ['./login-section.component.scss']
})
export class LoginSectionComponent implements OnInit {
  public showCreateSsoForm = false;
  
  @Input("selectedTab") selectedTab: any;
  @ViewChild('myDiv') myDiv: ElementRef<HTMLInputElement>;
  @ViewChild('myDrift') myDrift: ElementRef<HTMLInputElement>;
  @ViewChild('hideDrift') hideDrift: ElementRef<HTMLInputElement>;
  @ViewChild('logoutDrift') logoutDrift: ElementRef<HTMLInputElement>;
  @Input() finalData: any;
  //@Input() locationCountry: any;
  modalRef: BsModalRef;

  constructor(
    public auth: AuthService, 
    public userService: UserService, 
    public commonService: CommonService,
    private modalService: BsModalService
  ) { }

  ngOnInit (): void {
  }

  ngAfterViewInit () {
    AppUtil.saveToSession('driftDiv', this.myDiv.nativeElement.id);
    AppUtil.saveToSession('logoutDrift', this.logoutDrift.nativeElement.id);
  }

  /**
* @description Method to do login action
*/
  public doLogin (): void {
    const selectedTabParam = this.selectedTab.TAB_ID;
    this.userService.doLogin(selectedTabParam);
  }

  /**
* @description Method to do login action
*/
  public createAccount (): void {
    this.commonService.setNonSso(true);
    //For GA
    this.userService.emitEvent("NON SSO", "Non SSO User Login", "provisioning", 4)
    //For GA
  }

  /**
   * Method to show the create sso form
   */
  public showCreateSso(): void {
    this.showCreateSsoForm = true;
  }

  /**
* @description Method to redirect to submit case
*/
  public redirectTo(): void {
    const url: string = AppUtil.getSubmitCaseUrl();
    window.open(
      url,
      '_blank'
    );
  }

  /**
   * Method to open sign in modal
   * @param template template to open
   */
  public openModal(template: TemplateRef<any>): void {
    const config: ModalOptions = { class: 'modal-dialog-centered modal-lg' };
    this.modalRef = this.modalService.show(template, config);
  }

}
