import { environment } from "src/environments/environment";

export class AppUtil {
  constructor() { }

  /**
   * @description Method to check whether string exist in the array of string
   * @param parentArray Array to search an item
   * @param item Item to search
   */
  public static isStringInArray (
    parentArray: Array<string>,
    item: string
  ): boolean {
    if (
      parentArray &&
      typeof parentArray === "object" &&
      parentArray.some(x => item.includes(x))
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description Method to check whether sub string exist in string
   * @param parentString Parent string to search a sub string
   * @param item Item to search
   */
  public static isStringInString (parentString: string, item: string): boolean {
    return parentString.includes(item);
  }

  /**
   * @description Method to check and return object if item exist in the array of object
   * @param parentArray Array to search an item
   * @param key Object field name
   * @param type Type of Item to search
   * @param item Item to search
   */
  public static getItemInObjectArray (
    parentArray: Array<object>,
    key: string,
    type: string,
    item: string
  ): any {
    if (parentArray && parentArray.length > 0) {
      if (type === "number") {
        return parentArray.find((e: any) => e[key] == parseInt(item));
      } else {
        return parentArray.find((e: any) => e[key] == item);
      }
    }
  }

  /**
   * @description Convert Array to String
   * @param parentArray Array to convert to string
   */
  public static arrayToString (parentArray: Array<string>): string {
    if (parentArray && typeof parentArray === "object") {
      return parentArray.join(",");
    } else {
      return "";
    }
  }

  /**
   * @description Convert String to Array
   * @param parentString String to convert to Array
   */
  public static stringToArray (parentString: string): Array<string> {
    if (parentString && typeof parentString === "string") {
      return parentString.split(",");
    } else {
      return [""];
    }
  }

  /**
   * @name toCamelCase
   * @desc Convert to camel case
   */
  public static toCamelCase (input: string): string {
    const output = input
      .split(" ")
      .map((word, i) => {
        if (i === 0) return word.toLowerCase();
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join("");

    return output;
  }

  /**
   * @name setSessionStorage
   * @desc Set value to session storage of browser.
   */
  public static setSessionStorage (key: string, data: object | string): void {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  /**
   * @name getSessionStorage
   * @desc Get value from session storage of browser.
   */
  public static getSessionStorage (key: string): object {
    let sessionValue: any;
    if (sessionStorage.getItem(key) !== null) {
      sessionValue = JSON.parse(sessionStorage.getItem(key) || "");

    }
    return sessionValue ? sessionValue : {};
  }


  /**
   * @name saveToSession
   * @desc Save data to session storage.
   */
  public static saveToSession (key: string, data: object | string): void {
    this.setSessionStorage(key, data);
  }

  /**
   * @name getFromSession
   * @desc Get data from session.
   */
  public static getFromSession (key: string): object {
    let sessionData = this.getSessionStorage(key);
    if (!sessionData) {
      sessionData = {};
    }
    return sessionData;
  }

  /**
   * @name deleteSessionStorage
   * @desc Delete value from session storage of browser.
   */
  public static deleteSessionStorage (key: string): void {
    sessionStorage.removeItem(key);
  }

  ///

  /**
   * @name setLocalStorage
   * @desc Set value to local storage of browser.
   */
  public static setLocalStorage (key: string, data: object | string): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  /**
   * @name getLocalStorage
   * @desc Get value from local storage of browser.
   */
  public static getLocalStorage (key: string): object {
    let localValue: any;
    if (localStorage.getItem(key) !== null) {
      if (typeof (localStorage.getItem(key)) === "string") {
        localValue = localStorage.getItem(key);
      } else {
        localValue = JSON.parse(localStorage.getItem(key) || "");
      }
    }
    return localValue ? localValue : {};
  }


  /**
   * @name saveToLocal
   * @desc Save data to local storage.
   */
  public static saveToLocal (key: string, data: object | string): void {
    this.setLocalStorage(key, data);
  }

  /**
   * @name getFromLocal
   * @desc Get data from local.
   */
  public static getFromLocal (key: string): any {
    let localData = this.getLocalStorage(key);
    if (!localData) {
      localData = {};
    }
    return localData;
  }

  /**
   * @name deleteLocalStorage
   * @desc Delete value from local storage of browser.
   */
  public static deleteLocalStorage (key: string): void {
    localStorage.removeItem(key);
  }

  /**
  * @description Get base url of app
  */
  public static getBaseUrl (): string {
    return location.origin + '/home';
  }

  /**
  * @description Get base url of API
  */
  public static getAPIBaseUrl (): string {
    return environment.apiUrl
      ? environment.apiUrl
      : "";
  }

  /**
  * @description Get base url of Okta
  */
  public static getOktaBaseUrl (): string {
    return environment.oktaUrl
      ? environment.oktaUrl
      : "";
  }

  /**
  * @description Get okta client ID
  */
 public static getOktaClientID (): string {
  return environment.oktaClientId
    ? environment.oktaClientId
    : "";
}


  /**
  * @description Check whether object is empty or not
  */
  public static isEmptyObject (obj: object): boolean {
    return (obj && (Object.keys(obj).length === 0));
  }

  /**
  * @description Get internal user redirect url
  */
 public static getInternalUserRedirectUrl (): string {
  return environment.redirectUrlInternalUser
    ? environment.redirectUrlInternalUser
    : "";
  }

  /**
  * @description Get submit case url
  */
 public static getSubmitCaseUrl (): string {
  return environment.loginFailureScenario
    ? environment.loginFailureScenario
    : "";
  }
}
