<div class="col-12 col-md-8 content-border mt-3 pt-3 pb-3 card-padding">
  <div class="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center"
    *ngIf="!showCreateSsoForm">
    <div>
      <div class="title-text text-3xl pb-2 still-need">
        Still need help? – Contact Customer Service
      </div>
      <div class="login-text-color">
        In order to contact customer service, please click the <strong class="text-lg">Contact Customer Service</strong> button below.
          You will need to either log in with an existing email id and password or create a new user to get in contact with us.  
         
        <!-- If this is your first time visiting this site, please click the Create
        User button -->
      </div>
      <div class="btn-margin">
        <button type="button" class="btn btn-primary btn-height" (click)="openModal(template)">
          Contact Customer Service
        </button>
      </div>
      <ng-template #template>
        <div class="modal-body">
          <div>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6 pl-3 pr-3 divider">
              <div class="p-0">
              <div class="text-3xl sign-in-title mt-2">Existing User</div>
              <p class="text-md pt-3 mb-0 pl-0 popover-content login-text-color">
                If you have registered on our site before or have access to any D&B products, you can use your existing login credentials.
              </p>
              <button
              type="button"
              class="btn btn-primary btn-height mt-2 mb-3"
              (click)="doLogin()"
            >
              Log In
            </button>
            </div>
            </div>
            <div class="col-sm-12 col-md-6 pl-4 pr-3">
              <div class="p-0">
                <div class="text-3xl sign-in-title mt-2">New User</div>
                <p class="text-md pt-3 mb-0 pl-0 popover-content login-text-color">
                 
            If you do not have access to any D&B products or this is your first time using our site, please click the <strong class="text-lg">Create User</strong>  button to get started
                </p>
                  <button
                    type="button"
                    class="btn btn-primary btn-height mt-2 mb-3" 
                    (click)="showCreateSso();modalRef.hide()"
                  >
                    Create User
                  </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- <div class="btn-margin-link login-text-color">
        Cannot create a log in or experiencing other log in problems with this
        site, please
        <a (click)="redirectTo()" class="primary-color click-here">click here</a>
      </div> -->
    </div>
  </div>

  <!--create sso user start-->
  <div *ngIf="showCreateSsoForm">
    <app-create-sso-user [finalData]="finalData"></app-create-sso-user>
  </div>
  <!--create sso user end-->

  <div class="col-sm-8 display-inline text-align-right pl-0 pr-0 display-none">
    <button type="button"
      class="btn btn-light lg-btn-width mb-3 mt-3 box-min-height light-btn drift-open-chat display-none" #myDiv
      id="drift-open-chat" tabindex=0 onclick="DriftBot()" onkeypress="DriftBot()">
      Chat
    </button>
    <div class="load-drift display-none" #myDrift id="load-drift" tabindex=0 onclick="playBookLoad()"
      onkeypress="playBookLoad()">
      Load Drift
    </div>
    <div class="hide-drift display-none" #hideDrift id="hide-drift" tabindex=0 onclick="playBookHide()"
      onkeypress="playBookHide()">
      Hide Drift
    </div>
    <div class="logout-drift display-none" #logoutDrift id="logout-drift" tabindex=0 onclick="logoutDrift()"
      onkeypress="logoutDrift()">
      Logout Drift
    </div>
  </div>
</div>