import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SERVICE_GLOBAL } from 'src/app/shared/constants/services.global';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public serviceGlobal: any;
  private data = new Subject<any>();
  public data$ = this.data.asObservable();
  
  constructor(private http: HttpClient, private injector: Injector) {
    this.serviceGlobal = this.injector.get(SERVICE_GLOBAL);
  }

  /**
   * Method to emit the data from one component to another
   * @param data 
   */
  public emitData(data: any): void {
    this.data.next(data);
  }

   /**
	 * @description API call to get reason description
	 */
  public getReasonDetails (reasonDescription: string): Observable<any> {
    return this.http.post(this.serviceGlobal.GET_REASON_DESCRIPTION + "?reason_desc=" + reasonDescription, "");
  }
}
