import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UserRoutingModule } from "./user-routing.module";
import { HomeComponent } from "./components/home/home.component";
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { DropDownPanelComponent } from './components/home/drop-down-panel/drop-down-panel.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordionModule } from "ngx-bootstrap/accordion";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { AlertModule } from "ngx-bootstrap/alert";
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { FaqComponent } from './components/home/faq/faq.component';
import { LoginSectionComponent } from './components/home/login-section/login-section.component';
import { InternalUserSectionComponent } from './components/home/internal-user-section/internal-user-section.component';
import { CreateSsoUserComponent } from './components/home/create-sso-user/create-sso-user.component';
import { SearchComponent } from "./components/home/search-bar/search-bar.component";
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { HelpfulLinksComponent } from "./components/home/helpful-links/helpful-links.component";
import { HelpTopicsComponent } from "./components/home/help-topics/help-topics.component";
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { CreateUserFormComponent } from './components/home/create-user-form/create-user-form.component';
import { SharedModule } from "../../shared/shared.module";

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [HomeComponent, DropDownPanelComponent, FaqComponent, 
    LoginSectionComponent, InternalUserSectionComponent, CreateSsoUserComponent, 
    HelpfulLinksComponent, HelpTopicsComponent, SearchComponent, CreateUserFormComponent ],
  imports: [
    CommonModule,
    UserRoutingModule,
    FontAwesomeModule,
    TabsModule.forRoot(),
    ButtonsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    AccordionModule.forRoot(),
    TooltipModule.forRoot(),
    AlertModule.forRoot(),
    NgxIntlTelInputModule,
    AutocompleteLibModule,
    ModalModule,
    SharedModule
  ],
  exports: [SearchComponent, HelpfulLinksComponent, CreateSsoUserComponent, LoginSectionComponent, FaqComponent],
  providers: [BsModalService],
})
export class UserModule { }
