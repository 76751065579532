<div class="article-content-container">
  <div class="article-content row mb-2">
    <div class="col-md-8 col-sm-12 pt-4">
      <div id="pdfArticle" #pdfArticle><div id="search-header" class="title-text text-3xl" [innerHTML]="titleSelected"></div>
      <p class="col-md-10 text-md pt-3 pl-0 search-inner-text" id="search-text" [innerHTML]="selectedContent">
      </p></div>
      <div
      class="col-sm-12 mt-3 p-0 m-b-10"
      id="search-feedback"
    ></div><button *ngIf="titleSelected" class="btn btn-primary btn-height m-t-10" (click)="downloadAsPDF()">Export To PDF</button>
    
    <div class="col-12 col-md-12 content-border mt-3 mb-3 pt-3 pb-3 card-padding" *ngIf="titleSelected && !showCreateSsoForm">
      <div class="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center">
        <div class="sso-user-container">
          <div *ngIf="titleSelected !== 'Get a Status on an Existing Support Case'" class="title-text text-3xl pb-2 still-need">
            Still need help? – Contact Customer Service
          </div>
          <div class="non-logged-in-user" *ngIf="!authService.isAuthenticated()">
           
            <div class="login-text-color">
              In order to contact customer service, please click the <strong class="text-lg">Contact Customer Service</strong> button below.
                You will need to either log in with an existing email id and password or create a new user to get in contact with us.  
           
            </div>
            <div class="btn-margin">
              <button type="button" class="btn btn-primary btn-height mb-5" (click)="openModal(template)">
                Contact Customer Service
              </button>
              <ng-template #template>
                <div class="modal-body">
                  <div>
                    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-md-6 pl-3 pr-3 divider">
                      <div class="p-0">
                      <div class="text-3xl sign-in-title mt-2">Existing User</div>
                      <p class="text-md pt-3 mb-0 pl-0 popover-content article-text-color">
                        If you have registered on our site before or have access to any D&B products, you can use your existing login credentials.
                      </p>
                      <button
                      type="button"
                      class="btn btn-primary btn-height mt-2 mb-3"
                      (click)="getTabDetails()"
                    >
                      Log In
                    </button>
                    </div>
                    </div>
                    <div class="col-sm-12 col-md-6 pl-4 pr-3">
                      <div class="p-0">
                        <div class="text-3xl sign-in-title mt-2">New User</div>
                        <p class="text-md pt-3 mb-0 pl-0 popover-content article-text-color">
                         
                    If you do not have access to any D&B products or this is your first time using our site, please click the <strong class="text-lg">Create User</strong>  button to get started
                        </p>
                          <button
                            type="button"
                            class="btn btn-primary btn-height mt-2 mb-3" 
                            (click)="showCreateSso();modalRef.hide()"
                          >
                            Create User
                          </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
          
          <div class="logged-in-user" *ngIf="authService.isAuthenticated() && titleSelected !== 'Get a Status on an Existing Support Case'">
            <button type="button" class="btn btn-primary btn-height" (click)="getTabDetails()">
              Contact Us
            </button>
          </div>
        </div>
      </div>

      <!-- Case status section -->
      <div *ngIf="authService.isAuthenticated() && 
        showCaseStatus && titleSelected === 'Get a Status on an Existing Support Case'">
        <app-case-status-section
          [selectedFinalData]=null
        ></app-case-status-section>
      </div>
    </div>
    
    <!--create sso user start-->
      <div *ngIf="showCreateSsoForm" class="col-12 col-md-12 content-border mt-3 mb-3 pt-3 pb-3 card-padding">
        <app-create-sso-user></app-create-sso-user>
      </div>
    <!--create sso user end-->
    
    </div>
    <div class="col-sm-12 col-md-4 pt-4" *ngIf="relatedArticlesLength > 0">
      <div class="title-text text-3xl related-heading">Related Articles</div>
      <a *ngFor="let relatedArticle of relatedArticles" class="text-md link-style related-article" (click)="displayArticle(relatedArticle)"
        [innerHTML]="relatedArticle['Title']">
      </a>
    </div>
  </div>
</div>
