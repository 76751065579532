import { Component, OnInit } from '@angular/core';
import { AppUtil } from 'src/app/core/utils/utils';
import { CommonConstant } from 'src/app/shared/constants/common.constants';
import { CommonService } from 'src/app/shared/services/common/common.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-internal-user-section',
  templateUrl: './internal-user-section.component.html',
  styleUrls: ['./internal-user-section.component.scss']
})
export class InternalUserSectionComponent implements OnInit {

  constructor(public userService: UserService, public commonService: CommonService) { }

  ngOnInit (): void {
  }

  /**
* @description Method to redirect to external URL
*/
  public redirectTo (): void {
    const userData = this.userService.getUserData();
    // for GA
    this.userService.emitEvent("Submit Case", "primary", "Channel click internal", 8);
    // for GA
    const url: string = AppUtil.getInternalUserRedirectUrl();
    const redirectUrl = this.commonService.getAppendedUrl(url, userData);
    window.open(
      redirectUrl,
      '_blank'
    );
  }

}
